import React from 'react'
import Link from 'next/link'
import { THEME } from 'utils'
import StoolAndStarsIcon from 'icons/StoolAndStars'

const MinimalFooter = ({
  linkToggles = { termsOfUse: true, privacyPolicy: true, messagingTerms: true, subscriptionTerms: true },
  iconDimensions = { height: '2.5rem', width: '2.5rem' },
  backgroundColor,
  foregroundColor,
  fontSize,
  height = 'auto',
  boldLinks = false,
  stickyBottom,
  styles,
  children
}) => {
  return (
    <>
      <footer className='minimalFooter' style={styles}>
        <StoolAndStarsIcon {...iconDimensions} color={foregroundColor || THEME.COLORS.GRAY} />
        <p>
          &copy;{new Date().getFullYear()} Barstool Sports - All rights reserved.
          <br />
          {linkToggles?.termsOfUse && (
            <Link
              href={typeof linkToggles.termsOfUse === 'string' ? linkToggles.termsOfUse : '/terms-of-use'}
              legacyBehavior
            >
              <a target='_blank'>Terms of Use</a>
            </Link>
          )}
          {linkToggles?.privacyPolicy && (
            <Link href='/privacy-policy' legacyBehavior>
              <a target='_blank'>Privacy Policy</a>
            </Link>
          )}
          {linkToggles?.messagingTerms && (
            <Link href='/messaging-terms' legacyBehavior>
              <a target='_blank'>Messaging Terms</a>
            </Link>
          )}
          {linkToggles?.subscriptionTerms && (
            <Link href='/digital-sale-terms' legacyBehavior>
              <a target='_blank'>Digital Sale Terms</a>
            </Link>
          )}
        </p>
        {children}
      </footer>
      <style jsx>{`
        .minimalFooter {
          background: ${backgroundColor || '#fff'};
          position: relative;
          text-align: center;
          display: block;
          font-family: inherit;
          padding: 2rem 0;
          width: 100%;
          position: ${stickyBottom ? 'absolute' : 'auto'};
          bottom: ${stickyBottom ? stickyBottom : 'auto'};
          height: ${height};
        }
        .minimalFooter p {
          color: ${foregroundColor || THEME.COLORS.GRAY};
          margin-top: 0.5rem;
          letter-spacing: normal;
          font-size: ${fontSize || '16px'};
        }
        .minimalFooter p:last-child {
          margin-bottom: 0;
        }
        .minimalFooter a {
          display: inline-block;
          color: ${foregroundColor || '#999'};
          margin: 0.25rem;
          text-decoration: none;
          cursor: pointer;
          font-size: ${fontSize || '16px'};
          font-weight: ${boldLinks ? 700 : 'normal'};
        }
      `}</style>
    </>
  )
}

export default MinimalFooter
